@font-face {
  font-family: "neuelt";
  src: url("./assets/fonts/neuelt.ttf");
}

html {
  font-size: 16px !important;
}

body {
  background-color: #EAF2F6 !important;
  font-family: "neuelt", serif, sans-serif !important;
}

.chakra-accordion__button[aria-expanded="true"],
.sidebar-link.active {
  /* background-color: #ddd !important;
  color: #000 !important; */
}

.chakra-link.active {
  color: #602ACE;
  border-bottom: 2px solid #602ACE
}